import { FileFragment, type ImageFragment } from "@gql-schema";
import { ImageType, UrlHelper } from "@shared/utils/UrlHelper";
import { Icon } from "@ui/components/Icon/Icon";
import { ImageRoot, ImageRootProps } from "@ui/components/Image/styles/ImageRoot";
import { cx } from "@uxf/core/utils/cx";
import { ImgHTMLAttributes, ReactNode } from "react";

export interface ImageProps extends ImageRootProps {
    className?: string;
    classNameImg?: string;
    classNamePlaceholderSize?: string;
    customContent?: ReactNode;
    height?: number;
    imgProps?: Partial<Omit<ImgHTMLAttributes<HTMLImageElement>, "src">>;
    originalAspectRatio?: boolean;
    src?: string | FileFragment | ImageFragment | null;
    srcSet?: string | null;
    type?: ImageType;
    width?: number;
}

/* TODO: rework the whole component */
export function Image(props: ImageProps) {
    const {
        className,
        classNameImg,
        classNamePlaceholderSize = "w-12 h-12",
        $aspectRatio,
        $objectFit = "cover",
        $objectPosition = "center",
        customContent,
        height,
        imgProps,
        originalAspectRatio,
        src,
        srcSet,
        type,
        width,
        ...restProps
    } = props;

    const imageSrc = typeof src === "string" ? src : src ? UrlHelper.getImageUrl(src, width, height, type) : undefined;
    const imageSrcSet =
        typeof src === "string" ? srcSet : src ? UrlHelper.getImageHiDpiSrcSet(src, width, height, type) : undefined;

    return (
        <ImageRoot
            $alignItems="center"
            $aspectRatio={!originalAspectRatio ? $aspectRatio : undefined}
            $justifyContent="center"
            $objectFit={$objectFit}
            $objectPosition={$objectPosition}
            className={className}
            {...restProps}
        >
            {src ? (
                <img
                    className={cx(
                        "ui-image__element w-full",
                        !originalAspectRatio ? "absolute inset-0 h-full" : "h-auto",
                        classNameImg,
                    )}
                    height={height}
                    role={!imgProps?.alt ? "none" : undefined}
                    src={imageSrc ?? undefined}
                    srcSet={imageSrcSet ?? undefined}
                    width={width}
                    {...imgProps}
                    alt={imgProps?.alt ?? ""}
                />
            ) : (
                <div
                    className={cx(
                        "flex items-center justify-center rounded-lg bg-green-secondary",
                        !originalAspectRatio && $aspectRatio && "absolute",
                        classNamePlaceholderSize,
                    )}
                >
                    <Icon className="text-green" name="cross" size={16} />
                </div>
            )}
            {customContent}
        </ImageRoot>
    );
}
